import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import ExecutionEnvironment from 'exenv';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@mweb/zappos-ui/Accordion';
import { deepEqual } from 'fast-equals';

import { NEWLY_REGISTERED_COOKIE, OPAL_PROFILE_COOKIE } from 'constants/cookies';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { CHECKOUT_URL_RE } from 'common/regex';
import marketplace from 'cfg/marketplace.json';
import { getFooterHistoryRecos, getProfile, handleSignUpModalSubmit, initSignUpModal, setSignUpModal } from 'actions/headerfooter';
import { handleSubscribeSubmit } from 'actions/account/subscriptions';
import { getHeartCounts, getHearts, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { getHeartProps } from 'helpers/HeartUtils';
import LatencyTracking from 'helpers/LatencyTracking';
import SignUpModal from 'components/hf/SignUpModal';
import FooterNav from 'components/hf/zappos/FooterNav';
import FooterSignUp from 'components/hf/zappos/FooterSignUp';
import FooterRecos from 'components/hf/zappos/FooterRecos';
import FooterBottom from 'components/hf/zappos/FooterBottom';
import HeartLoginPrompt from 'components/common/HeartLoginPrompt';
import { trackEvent } from 'helpers/analytics';
import { FEATURE_RECOMMENDATIONS_SEARCH } from 'constants/features';
import { useFlag } from 'containers/UnleashFlagProvider';
import FooterSocialIcons from 'components/hf/zappos/FooterSocialIcons/FooterSocialIcons';
import FooterMenuHeading from 'components/hf/zappos/FooterMenuHeading/FooterMenuHeading';
import { cn } from 'helpers/classnames';

import css from 'styles/containers/hf/zappos/footer.scss';

const { hasHeartCounts, hasHearting } = marketplace;

function FooterMenusImpl({ footerMenus, testId }) {
  return (
    footerMenus?.length > 0 && (
      <>
        <div className="flex flex-col justify-between gap-x-6 gap-y-3 sm:hidden sm:flex-row">
          {/* mobile menus */}
          {footerMenus.map(({ footerMenu, heading: { text } = {} } = {}) => (
            <Accordion key={`accordion-${text}`} type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger data-test-id={testId(`footerAccordionTrigger-${text?.replace(/\s/g, '-')}`)}>
                  <FooterMenuHeading text={text} />
                </AccordionTrigger>
                <AccordionContent>
                  <div className="flex flex-col gap-y-3">
                    <FooterNav data={{ footerMenu, heading: { text: '' } }} />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ))}
        </div>
        {/* desktop menus */}
        <div className="hidden flex-col gap-x-8 sm:flex sm:flex-row">
          {footerMenus.map(data => (
            <div key={`d-${data?.heading?.text}`} className="w-full">
              <FooterNav data={data} />
            </div>
          ))}
        </div>
      </>
    )
  );
}

const FooterMenus = React.memo(FooterMenusImpl, deepEqual);

export class Footer extends Component {
  static displayName = 'Footer';
  componentDidMount() {
    const { getFooterHistoryRecos, isRemote, latencyTracking, recoSearchFeatureFlag } = this.props;
    getFooterHistoryRecos({ recoSearchFeatureFlag });

    // Setup latencyTracking for init page load
    // Use latencyTracking prop for testing
    this.latencyTracking = latencyTracking || new LatencyTracking();

    this.getOpalProfile();

    this.signUpModalCheck();

    if (isRemote && !this.rootSelector) {
      /*
        "Hydrating" React Portals isn't yet supported so we get a console
        warning when serving the remote HF in dev envs.
        But worry not, nothing is actually wrong.
        https://github.com/facebook/react/issues/13097
        https://github.com/facebook/react/issues/12615
      */
      this.rootSelector = document.getElementById('martyRemoteFooter');
      this.rootSelector.innerHTML = '';
      /*
        sometimes render runs before didMount. this ensures portal connects
        & footer doesn't disappear
      */
      this.forceUpdate();
    }
  }

  shouldComponentUpdate(nextProps) {
    const p = this._getUsedProps(this.props);
    const n = this._getUsedProps(nextProps);

    return !this.props || !deepEqual(n, p);
  }

  componentDidUpdate(prevProps) {
    const {
      router: {
        location: { pathname }
      },
      getFooterHistoryRecos,
      getHearts,
      getHeartCounts,
      recoSearchFeatureFlag
    } = this.props;
    const hasLocationChanged = pathname !== prevProps.router.location.pathname;

    // Call for new footer recos when switching pages
    if (hasLocationChanged && this.shouldShowFooterRecos()) {
      getFooterHistoryRecos({ recoSearchFeatureFlag });
    }

    if (hasLocationChanged) {
      this.latencyTracking.triggerSoftNavigation(); // Fire latency tracking for client routing
      this.getOpalProfile();
    }

    this.signUpModalCheck();

    // Get hearting list
    const nextRecs = this.props.footerRecos?.recs;
    const prevRecs = prevProps.footerRecos?.recs;
    if (prevRecs !== nextRecs) {
      getHearts();
      if (hasHeartCounts) {
        getHeartCounts(nextRecs);
      }
    }
  }

  signUpModalCheck = () => {
    const {
      router: {
        location: { pathname }
      },
      isNewlyRegistered,
      initSignUpModal
    } = this.props;
    if (isNewlyRegistered && !pathname.match(CHECKOUT_URL_RE)) {
      initSignUpModal();
    }
  };

  getOpalProfile = () => {
    const { hasProfileCookie, hasXMain, getProfile } = this.props;
    if (!hasProfileCookie && hasXMain) {
      getProfile();
    }
  };

  shouldShowFooterRecos = () => {
    const {
      content,
      router: {
        location: { pathname }
      }
    } = this.props;
    // Don't show on some urls including checkout
    const footerRecoRegEx =
      /^(?:\/marty)?\/(influencer\/hub\/shoppablePost|returnLabel|m?checkout|(create-label)|about\/support)($|\/)(?!(thankyou))/;

    // Symphony killswitch
    const isOn = content?.Footer?.slotData?.['ad']?.componentName === 'recommenderHistoryFooter';
    return ExecutionEnvironment.canUseDOM && !footerRecoRegEx.test(pathname) && isOn;
  };

  _getUsedProps(props) {
    const {
      handleSubscribeSubmit,
      footerRecos,
      content,
      setSignUpModal,
      heartLoginPrompt = { isOpen: false },
      isSignUpModalOpen,
      handleSignUpModalSubmit,
      isFooterSubscribeSubmitted,
      isRemote,
      forceRender,
      isCustomer,
      heartProduct,
      hearts,
      products,
      toggleHeartingLoginModal = () => {},
      trackEvent,
      unHeartProduct
    } = props || this.props;
    return {
      handleSubscribeSubmit,
      footerRecos,
      content,
      setSignUpModal,
      heartLoginPrompt,
      isSignUpModalOpen,
      handleSignUpModalSubmit,
      isFooterSubscribeSubmitted,
      isRemote,
      forceRender,
      isCustomer,
      heartProduct,
      hearts,
      products,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct
    };
  }

  render() {
    const { testId } = this.context;
    const {
      handleSubscribeSubmit,
      footerRecos,
      content,
      setSignUpModal,
      heartLoginPrompt = { isOpen: false },
      isSignUpModalOpen,
      handleSignUpModalSubmit,
      isFooterSubscribeSubmitted,
      isRemote,
      forceRender,
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal = () => {},
      trackEvent,
      unHeartProduct
    } = this._getUsedProps();
    const heartProps = {
      hasHearting,
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct
    };

    const {
      'footermenu-1': exploreMenu,
      'footermenu-4': fitInfoMenu,
      'footermenu-3': customerServiceMenu,
      'footermenu-5': aboutMenu,
      'footermenu-6': resourcesMenu,
      'sign-up-modal': signUpModal,
      'sign-up-form': signUpForm,
      'social-links': socialLinks,
      'copyright': copyRight,
      'legal-links': legalLinks
    } = content?.Footer?.slotData || {};

    const { footerMenu: resourcesFooterMenu = [], ...restResourcesMenu } = resourcesMenu || {};
    const combinedResourcesMenu = fitInfoMenu?.footerMenu
      ? {
          ...restResourcesMenu,
          footerMenu: [...fitInfoMenu?.footerMenu, ...resourcesFooterMenu]
        }
      : resourcesMenu;

    const footerMenus = [aboutMenu, customerServiceMenu, combinedResourcesMenu, exploreMenu]?.filter(menu => menu !== undefined);

    const heartsData =
      ExecutionEnvironment.canUseDOM &&
      getHeartProps(heartProps, {
        heartEventName: 'TE_FOOTER_HEART',
        unHeartEventName: 'TE_FOOTER_UNHEART'
      });

    /** render the actual footer now */
    const footer = (
      <>
        <SignUpModal
          data={signUpModal}
          isSignUpModalOpen={isSignUpModalOpen}
          setSignUpModal={setSignUpModal}
          handleSignUpModalSubmit={handleSignUpModalSubmit}
        />
        {this.shouldShowFooterRecos() && footerRecos && <FooterRecos recos={footerRecos} heartsData={heartsData} />}
        {hasHearting && <HeartLoginPrompt isOpen={heartLoginPrompt.isOpen} id={heartLoginPrompt.id} toggleModal={toggleHeartingLoginModal} />}
        <FooterSignUp handleSubscribeSubmit={handleSubscribeSubmit} isFooterSubscribeSubmitted={isFooterSubscribeSubmitted} signUp={signUpForm} />
        <div className={cn('flex w-full justify-center px-5 py-6 sm:px-12 sm:pb-8 sm:pt-10', 'bg-default-minimal')}>
          <footer className={css.footer} role="contentinfo" data-test-id={testId('footerElement')}>
            <div className={css.footerContainer}>
              <h2 className={'sr-only'} data-test-id={testId('footerHeading')}>
                Zappos Footer
              </h2>
              <FooterSocialIcons links={socialLinks} />
              <FooterMenus {...{ footerMenus, testId }} />
              <hr className={cn('hidden border-t border-dashed sm:block', 'text-primary')} />
            </div>
            <FooterBottom copyright={copyRight} bottomLinks={legalLinks} />
          </footer>
        </div>
      </>
    );

    if (isRemote && ExecutionEnvironment.canUseDOM && this.rootSelector) {
      return createPortal(footer, this.rootSelector);
    } else if (!isRemote || forceRender) {
      return footer;
    }
    return null;
  }
}

export const withUseFlagHOC = Component => props => {
  const recoSearchFeatureFlag = useFlag(FEATURE_RECOMMENDATIONS_SEARCH);

  return <Component recoSearchFeatureFlag={recoSearchFeatureFlag} {...props} />;
};

function mapStateToProps(state) {
  const isCustomer = !!(ExecutionEnvironment.canUseDOM && state.cookies['x-main']);
  const { pageView: { pageType } = {} } = state;
  return {
    pageType,
    isCustomer,
    content: state.headerFooter.content,
    footerRecos: state.headerFooter.footerRecos,
    isFooterSubscribeSubmitted: state.headerFooter.isFooterSubscribeSubmitted,
    isSignUpModalOpen: state.headerFooter.isSignUpModalOpen,
    router: state.router,
    isNewlyRegistered: !!state.cookies[NEWLY_REGISTERED_COOKIE],
    hasProfileCookie: !!state.cookies[OPAL_PROFILE_COOKIE],
    hasXMain: !!state.cookies['x-main'],
    isRemote: state.headerFooter.isRemote,
    heartLoginPrompt: state.hearts.heartLoginPrompt
  };
}

Footer.defaultProps = {
  trackEvent
};

Footer.contextTypes = {
  testId: PropTypes.func
};

const FooterConnected = connect(mapStateToProps, {
  handleSubscribeSubmit,
  getFooterHistoryRecos,
  initSignUpModal,
  setSignUpModal,
  handleSignUpModalSubmit,
  getProfile,
  getHearts,
  getHeartCounts,
  heartProduct,
  unHeartProduct,
  toggleHeartingLoginModal
})(Footer);

const FooterConnectedWithErrorBoundary = withErrorBoundary('Footer', FooterConnected);
export default withUseFlagHOC(FooterConnectedWithErrorBoundary);
